import { m } from 'framer-motion';
// next
import NextLink from 'next/link';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container } from '@mui/material';
// layouts
import Layout from '../layouts';
// components
import Page from '../components/Page';
import { MotionContainer, varBounce } from '../components/animate';
// assets
import Logo from '../components/Logo';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

Page404.getLayout = function getLayout(page) {
  return <Layout variant="logoOnly">{page}</Layout>;
};

// ----------------------------------------------------------------------

export default function Page404() {
  return (
    <Page title="404 Page Not Found">
      <Container component={MotionContainer}>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <m.div variants={varBounce().in}>
            <Typography variant="h3" paragraph>
              Désolé, page non trouvée !
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <Typography sx={{ color: 'text.secondary' }}>
              Désolé, nous n'avons pas pu trouver la page que vous recherchez.
              Peut-être avez-vous mal saisi l'URL ? Vérifiez bien votre
              orthographe.
            </Typography>
          </m.div>

          <NextLink href="/" passHref>
            <Button size="large" variant="contained" sx={{ margin: 10 }}>
              Accueil
            </Button>
          </NextLink>

          <m.div variants={varBounce().in}>
            <Logo />
          </m.div>
        </ContentStyle>
      </Container>
    </Page>
  );
}
